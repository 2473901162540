import React, { Suspense, useLayoutEffect } from "react";
import { BrowserRouter as Router, Route, withRouter } from "react-router-dom";
import Preloader from "./components/layouts/Preloader";

const Home = React.lazy(() => import("./components/pages/Homefour"));
const About = React.lazy(() => import("./components/pages/About"));
const Contact = React.lazy(() => import("./components/pages/Contact"));
const Privacy = React.lazy(() => import("./components/pages/Privacy"));
const Terms = React.lazy(() => import("./components/pages/Terms"));
const Menu = React.lazy(() => import("./components/pages/Menu"));
const CateringMenu = React.lazy(() => import("./components/pages/CateringMenu"));
const SpecialsMenu = React.lazy(() => import("./components/pages/SpecialsMenu"));
const Gallery = React.lazy(() => import("./components/pages/Gallery"));
const NewPage = React.lazy(() => import("./components/pages/NewPage"));
const Page = React.lazy(() => import("./components/pages/Page"));
const Jobs = React.lazy(() => import("./components/pages/Jobs"));

const ScrollToTop = withRouter(({ children, location: { pathname } }) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children || null;
});

function App() {
  return (
    <Router>
      <Suspense fallback={<Preloader />}>
        <ScrollToTop>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/menu" component={Menu} />
          <Route path="/catering-menu" component={CateringMenu} />
          <Route path="/specials-menu" component={SpecialsMenu} />
          <Route path="/contact" component={Contact} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/terms" component={Terms} />
          <Route path="/gallery" component={Gallery} />
          <Route path="/newpage" component={NewPage} />
          <Route path="/page" component={Page} />
          <Route path="/jobs" component={Jobs} />
        </ScrollToTop>
      </Suspense>
    </Router>
  );
}

export default App;
